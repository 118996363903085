import React, { useEffect, useState, useRef } from 'react';
import styles from './Section2.module.scss';
// 이미지 불러오기
import ex1Image from '../../assets/ex1.jpg';
import ex2Image from '../../assets/ex2.jpg';
import ex3Image from '../../assets/ex3.jpg';

interface ContentItem {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
  bulletPoints: string[]; // 추가된 말머리 기호 항목들
}

const Section2: React.FC = () => {
  const [visibleItems, setVisibleItems] = useState<number[]>([]);
  const [titleVisible, setTitleVisible] = useState<boolean>(false);
  const sectionRef = useRef<HTMLElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  // 콘텐츠 데이터
  const contentItems: ContentItem[] = [
    {
      id: 1,
      title: "마케팅 자동화",
      description: "광고 분석부터 입찰까지,\n반복되는 마케팅 업무를 자동화합니다.",
      imageUrl: ex1Image,
      bulletPoints: [
        "리포트 자동 생성 및 공유 (Google Sheets, Slack 등)",
        "키워드 입찰 자동화 (네이버 키워드광고 API 연동)",
        "스마트스토어/쇼핑몰 관리 자동화 (재고, 주문, 리뷰 등)"
      ]
    },
    {
      id: 2,
      title: "CRM 업무 자동화",
      description: "고객을 더 잘 이해하고, 덜 반복적으로 관리할 수 있도록\n고객관리 시스템을 자동화합니다",
      imageUrl: ex2Image,
      bulletPoints: [
        "상담 기록 및 후속 조치",
        "고객 상태관리, 소구기존",
        "CRM 환경(엑셀, 웹툴 등) 연동 개발"
      ]
    },
    {
      id: 3,
      title: "물류/배송 자동화 (쿠팡·네이버 중심)",
      description: "복잡한 물류 프로세스를\n플랫폼에 맞게 자동화합니다.",
      imageUrl: ex3Image,
      bulletPoints: [
        "해외 매체 소싱 자동화",
        "쿠팡 WMS 자동 입출고 처리",
        "네이버 스마트스토어 송장 등록 및 상태 추적 자동화"
      ]
    },
    {
      id: 4,
      title: "데이터 수집·분석·시각화",
      description: "우리는 단순한 시스템만 만드는 것이 아닙니다.\n데이터를 수집하고, 처리하고, 보이게 만드는 일도 잘합니다.",
      imageUrl: ex1Image, // 이미지 재사용
      bulletPoints: [
        "다양한 사이트의 정보 자동 수집 (크롤링/스크래핑)",
        "실시간 대시보드 구축 (업무/성과 모니터링)",
        "API 통합 및 시각화 시스템 개발"
      ]
    }
  ];

  // 화면 크기 감지
  const [windowWidth, setWindowWidth] = useState<number>(
    typeof window !== 'undefined' ? window.innerWidth : 0
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // IntersectionObserver를 사용하여 요소가 화면에 보이는지 감지
  useEffect(() => {
    // 타이틀 관찰자
    const titleObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setTitleVisible(true);
          titleObserver.unobserve(entry.target);
        }
      });
    }, {
      threshold: 0.1
    });

    if (titleRef.current) {
      titleObserver.observe(titleRef.current);
    }

    // 콘텐츠 항목 관찰자
    const itemOptions = {
      root: null,
      rootMargin: '0px',
      threshold: windowWidth <= 768 ? 0.05 : 0.1 // 모바일에서는 더 일찍 감지
    };

    const itemObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const id = Number(entry.target.getAttribute('data-id'));
          setVisibleItems(prev => {
            if (!prev.includes(id)) {
              return [...prev, id];
            }
            return prev;
          });
        }
      });
    }, itemOptions);

    // 각 항목에 observer 등록
    itemRefs.current.forEach(ref => {
      if (ref) itemObserver.observe(ref);
    });

    return () => {
      if (titleRef.current) titleObserver.unobserve(titleRef.current);
      itemRefs.current.forEach(ref => {
        if (ref) itemObserver.unobserve(ref);
      });
    };
  }, [windowWidth]);

  // 화면 크기에 따른 텍스트와 이미지 순서 결정
  const getContentRowLayout = (item: ContentItem, isVisible: boolean) => {
    // 모바일 화면에서는 항상 이미지가 먼저 나오도록
    if (windowWidth <= 1024) {
      return (
        <>
          <div className={`${styles.imageContainer} ${isVisible ? styles.animateImage : ''}`}>
            <div className={styles.imagePlaceholder}>
              {item.imageUrl ? (
                <img src={item.imageUrl} alt={item.title} loading="lazy" />
              ) : (
                <span>이미지 {item.id}</span>
              )}
            </div>
          </div>
          
          <div className={`${styles.textContent} ${isVisible ? styles.animateText : ''}`}>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            
            {/* 말머리 기호 항목 추가 */}
            <ul className={styles.bulletPoints}>
              {item.bulletPoints.map((point, i) => (
                <li key={i}>{point}</li>
              ))}
            </ul>
          </div>
        </>
      );
    }
    
    // 데스크톱 화면에서는 항상 동일한 순서로 JSX 구성
    // CSS의 reverse 클래스만으로 순서 변경
    return (
      <>
        <div className={`${styles.imageContainer} ${isVisible ? styles.animateImage : ''}`}>
          <div className={styles.imagePlaceholder}>
            {item.imageUrl ? (
              <img src={item.imageUrl} alt={item.title} loading="lazy" />
            ) : (
              <span>이미지 {item.id}</span>
            )}
          </div>
        </div>
        
        <div className={`${styles.textContent} ${isVisible ? styles.animateText : ''}`}>
          <h3>{item.title}</h3>
          <p>{item.description}</p>
          
          {/* 말머리 기호 항목 추가 */}
          <ul className={styles.bulletPoints}>
            {item.bulletPoints.map((point, i) => (
              <li key={i}>{point}</li>
            ))}
          </ul>
        </div>
      </>
    );
  };

  return (
    <section ref={sectionRef} className={styles.section2}>
      <div className={styles.container}>
        <div ref={titleRef} className={styles.sectionTitle}>
          
        </div>
        
        {contentItems.map((item, index) => {
          const isVisible = visibleItems.includes(item.id);
          // ID가 짝수일 때만 reverse 클래스 적용
          const isEvenId = item.id % 2 === 0;
          
          return (
            <div 
              key={item.id}
              ref={(el: HTMLDivElement | null): void => {
                itemRefs.current[index] = el;
              }}
              data-id={item.id}
              className={`${styles.contentRow} ${isEvenId && windowWidth > 1024 ? styles.reverse : ''} ${isVisible ? styles.visible : ''}`}
            >
              {getContentRowLayout(item, isVisible)}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Section2;