import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import styles from './AboutScreen.module.scss';
import Section2 from '../../components/Main/Section3';

const AboutScreen: React.FC = () => {
  const { mode } = useSelector((state: RootState) => state.theme);
  const [isVisible, setIsVisible] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`${styles.aboutScreen} ${mode === 'light' ? styles.light : styles.dark}`}>
      <section className={styles.heroSection}>
        <div 
          ref={titleRef} 
          className={`${styles.titleContainer} ${isVisible ? styles.visible : ''}`}
        >
          <div className={styles.row}>
            <h2 className={styles.today}>Today</h2>
          </div>
          <div className={styles.row}>
            <h2 className={styles.we}>We</h2>
          </div>
          <div className={styles.row}>
            <h2 className={styles.need}>Need</h2>
          </div>
          <div className={styles.row}>
            <h2 className={styles.minimize}>minimize</h2>
          </div>
        </div>
      </section>
      <Section2 />
    </div>
  );
};

export default AboutScreen;