import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import styles from './work1.module.scss';

// 예시 이미지 임포트
// 실제 이미지 경로로 변경해야 합니다
import image1 from '../../assets/work1-1.png';
import image2 from '../../assets/work1-2.png';
import heroImage from '../../assets/ex8.jpg';

// 이미지 갤러리 스타일 임포트
import galleryStyles from '../imageGalleryPreview/imageGalleryPreview.module.scss';
import Contact from '../../components/Contact/Contact';
interface WorkImage {
  id: number;
  src: string;
  alt: string;
  text: string;
}

interface CircleFeature {
  id: number;
  src: string;
  alt: string;
  title: string;
  description: string;
}

// 이미지 갤러리 인터페이스
interface ImageItem {
  id: number;
  src: string;
  alt: string;
  title: string;
  description: string;
}

const Work1: React.FC = () => {
  const { mode } = useSelector((state: RootState) => state.theme);
  const [imagesVisible, setImagesVisible] = useState<boolean[]>([false, false, false]);
  const [titleVisible, setTitleVisible] = useState<boolean>(false);
  const [descriptionVisible, setDescriptionVisible] = useState<boolean>(false);
  const [featuresVisible, setFeaturesVisible] = useState<boolean>(false);
  const [galleryVisible, setGalleryVisible] = useState<boolean>(false);
  const imagesRef = useRef<(HTMLDivElement | null)[]>([]);
  const titleRef = useRef<HTMLDivElement>(null);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const featuresRef = useRef<HTMLDivElement>(null);
  const galleryRef = useRef<HTMLDivElement>(null);
  
  // 이미지 갤러리 관련 상태
  const [selectedImage, setSelectedImage] = useState<ImageItem | null>(null);

  // 샘플 이미지 데이터
  const workImages: WorkImage[] = [
    {
      id: 1,
      src: image1,
      alt: "AS IS",
      text: "업무의 모든 과정을 최소화 시킬 수 있도록 ERP솔루션에 모든 과정을 관리할 수 있게 설계하였습니다"
    },
    // {
    //   id: 2,
    //   src: image2,
    //   alt: "To Be",
    //   text: "두 번째 프로젝트 이미지에 대한 설명입니다. 여기에는 작업에 대한 핵심 내용이 포함되어 있습니다."
    // },
  ];

  // 원형 이미지와 설명을 위한 데이터
  const circleFeatures: CircleFeature[] = [
    {
      id: 1,
      src: image1,
      alt: "주요 특징 1",
      title: "쿠팡 발주 연동",
      description: "복잡한 엑셀 발주서를 ERP와 연동하여 쉽고 빠르게 업무진행이 가능합니다"
    },
    {
      id: 2,
      src: image2,
      alt: "주요 특징 2",
      title: "재고 및 배송관리",
      description: "자체 프로그램을 통한 바코드 방식으로 입고부터 배송까지 상품을 추적 및 관리 할 수 있습니다"
    },
    {
      id: 3,
      src: image1,
      alt: "주요 특징 3",
      title: "쿠팡 견적 자동화",
      description: "견적서를 양식에 맞게 미리 준비하고 한번에 관리 할 수 있습니다."
    }
  ];

  // 이미지 갤러리 데이터 - 최종 버전
  const galleryImages: ImageItem[] = [
    {
      id: 1,
      src: image1,
      alt: "발주서 연동",
      title: "발주서 연동",
      description: "직관적인 UI로 설계된 주문 관리 대시보드는 엑셀방식의 발주서를 한눈에 확인할 수 있게 해줍니다."
    },
    {
      id: 2,
      src: image2,
      alt: "재고 추적",
      title: "재고 추적",
      description: "ERP로 상품의 위치와 상품 데이터를 관리하여 재고를 보다 쉽게 관리할 수 있습니다"
    },
    {
      id: 3,
      src: image1, 
      alt: "바코드 입력",
      title: "바코드 입력",
      description: "상품 관리를 바코드를 통해 쉽고 간편하게 관리 할 수 있습니다."
    },
    {
      id: 4,
      src: image2, // 네 번째 이미지 - 실제 이미지로 교체 필요
      alt: "입출고 연동",
      title: "입출고 연동",
      description: "바코드를 찍는것만으로도 입출고와 재고관리를 간편하게 할 수 있습니다."
    }
  ];

  // 컴포넌트 마운트 시 첫 번째 이미지 선택
  useEffect(() => {
    if (galleryImages.length > 0) {
      setSelectedImage(galleryImages[0]);
    }
  }, []);

  // IntersectionObserver를 사용하여 요소가 화면에 보이는지 감지
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1 // 10% 이상 보이면 감지
    };

    // 타이틀 관찰자
    const titleObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setTitleVisible(true);
          titleObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 원형 이미지 섹션 관찰자
    const featuresObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setFeaturesVisible(true);
          featuresObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 이미지 관찰자
    const imageObservers = imagesRef.current.map((ref, index) => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setImagesVisible(prev => {
              const newState = [...prev];
              newState[index] = true;
              return newState;
            });
            observer.unobserve(entry.target);
          }
        });
      }, { ...observerOptions, threshold: 0.2 });
      return observer;
    });

    // 갤러리 관찰자
    const galleryObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setGalleryVisible(true);
          galleryObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 설명 관찰자
    const descriptionObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setDescriptionVisible(true);
          descriptionObserver.unobserve(entry.target);
        }
      });
    }, observerOptions);

    // 각 요소에 관찰자 등록
    if (titleRef.current) {
      titleObserver.observe(titleRef.current);
    }

    if (featuresRef.current) {
      featuresObserver.observe(featuresRef.current);
    }

    imagesRef.current.forEach((ref, index) => {
      if (ref) imageObservers[index].observe(ref);
    });

    if (galleryRef.current) {
      galleryObserver.observe(galleryRef.current);
    }

    if (descriptionRef.current) {
      descriptionObserver.observe(descriptionRef.current);
    }

    // 컴포넌트 언마운트 시 관찰자 해제
    return () => {
      if (titleRef.current) titleObserver.unobserve(titleRef.current);
      
      if (featuresRef.current) featuresObserver.unobserve(featuresRef.current);
      
      imagesRef.current.forEach((ref, index) => {
        if (ref) imageObservers[index].unobserve(ref);
      });
      
      if (galleryRef.current) galleryObserver.unobserve(galleryRef.current);
      
      if (descriptionRef.current) descriptionObserver.unobserve(descriptionRef.current);
    };
  }, []);

  // 이미지 선택 핸들러
  const handleImageSelect = (image: ImageItem) => {
    setSelectedImage(image);
  };

  return (
    <div className={`${styles.work1Container} ${mode === 'dark' ? styles.dark : ''}`}>
        <div 
          ref={titleRef} 
          className={`${styles.titleSection} ${titleVisible ? styles.visible : ''}`}
        >
          <h1 className={styles.title}>쿠팡 ERP 자동화 솔루션</h1>
          <div className={styles.overlay}></div>
          <img 
          src={heroImage} 
          alt="쿠팡 ERP 자동화 솔루션" 
          className={styles.backgroundImage} 
        />
        </div>
        <p className={styles.subtitle}>
          <span className={styles.impact}>쿠팡 로켓</span>의 번거로움을 ERP로 한번에!<br />
          <span className={styles.impact}>발주서 관리부터 재고, 배송, 입고, 견적까지</span><br /> 미니마이즈의 노하우로 쉽고 빠르게 해결하세요
        </p>
      {/* 새로운 원형 이미지 섹션 */}
      <div
        ref={featuresRef}
        className={`${styles.featuresSection} ${featuresVisible ? styles.visible : ''}`}
      >
        <div className={styles.featuresContainer}>
          {circleFeatures.map((feature, index) => (
            <div 
              key={feature.id} 
              className={styles.featureItem}
              style={{ animationDelay: `${index * 0.2}s` }}
            >
              <div className={styles.circleImageContainer}>
                <img src={feature.src} alt={feature.alt} className={styles.circleImage} />
              </div>
              <div className={styles.featureContent}>
                <h3 className={styles.featureTitle}>{feature.title}</h3>
                <p className={styles.featureDescription}>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* 최종 수정된 이미지 갤러리 - 번호 추가 */}
      <div 
        ref={galleryRef}
        className={`${styles.galleryPreviewSection} ${galleryVisible ? styles.visible : ''}`}
      >
        <div className={galleryStyles.galleryContainer}>
          <div className={galleryStyles.galleryWrapper}>
            {/* 왼쪽 섹션 - 큰 이미지 */}
            <div className={galleryStyles.leftSection}>
              <div className={galleryStyles.mainImageWrapper}>
                {selectedImage && (
                  <img 
                    src={selectedImage.src} 
                    alt={selectedImage.alt}
                    className={galleryStyles.mainImage}
                  />
                )}
              </div>
            </div>
            
            {/* 오른쪽 섹션 - 선택된 이미지의 번호, 제목과 설명 */}
            <div className={galleryStyles.rightSection}>
              {selectedImage && (
                <>
                  <span className={galleryStyles.imageNumber}>0{selectedImage.id}</span>
                  <h2 className={galleryStyles.imageTitle}>{selectedImage.title}</h2>
                  <p className={galleryStyles.imageDescription}>{selectedImage.description}</p>
                </>
              )}
              
              {/* 가로로 정렬된 썸네일 컨테이너 */}
              <div className={galleryStyles.thumbnailContainer}>
                {galleryImages.map((image) => (
                  <div 
                    key={image.id}
                    className={`${galleryStyles.thumbnailItem} ${selectedImage && selectedImage.id === image.id ? galleryStyles.active : ''}`}
                    onClick={() => handleImageSelect(image)}
                  >
                    <div className={galleryStyles.thumbnailImageWrapper}>
                      <img 
                        src={image.src} 
                        alt={image.alt}
                        className={galleryStyles.thumbnailImage}
                      />
                    </div>
                    <div className={galleryStyles.thumbnailContent}>
                      <span className={galleryStyles.thumbnailNumber}>0{image.id}</span>
                      <h3 className={galleryStyles.thumbnailTitle}>{image.title}</h3>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.imageGallery}>
        {workImages.map((image, index) => (
          <div 
            key={image.id}
            ref={(el) => { imagesRef.current[index] = el }}
            className={`${styles.imageContainer} ${imagesVisible[index] ? styles.visible : ''}`}
            style={{ animationDelay: `${index * 0.2}s` }}
          >
            <div className={styles.imageWrapper}>
              <img src={image.src} alt={image.alt} className={styles.workImage} />
            </div>
            <p className={styles.imageText}>{image.text}</p>
          </div>
        ))}
      </div>
      
      <div 
        ref={descriptionRef}
        className={`${styles.descriptionSection} ${descriptionVisible ? styles.visible : ''}`}
      >
        <div className={styles.descriptionWrapper}>
          <h2 className={styles.descriptionTitle}>프로젝트 상세 설명</h2>
          <p className={styles.descriptionText}>
            로켓배송의 복잡하고 어렵던 업무를 ERP를 통하여 쉽고 간편하게 만들 수 있는것에 집중하였습니다.
            기존의 복잡하던 엑셀 방식의 업무에서 벗어나 직관적인 UI를 통하여 실수와 중복으로 소비되는 시간을 줄이고
            바코드 관리방식을 통하여 업무의 난이도를 낮췄습니다.
          </p>
          <p className={styles.descriptionText}>
            미니마이즈를 통해 발주서 관리부터 재고, 배송, 입고, 견적까지 로켓배송의 모든 과정을 쉽고 간편하게 관리해보세요.
            사용자에게 맞춤 솔루션과 익숙한 UI를 제공해드립니다.            
          </p>
        </div>
      </div>
      <Contact 
        backgroundColor={mode === 'dark' ? '#2a2a2a' : '#f9f9f9'}
        textColor={mode === 'dark' ? '#f0f0f0' : '#333'}
      />
    </div>
  );
};

export default Work1;