import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { projectsData, Project } from '../../data/projectsData';
import styles from './WorksScreen.module.scss';
import TitleImage from '../../assets/ex8.jpg';

const WorksScreen: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>(projectsData);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isTitleVisible, setIsTitleVisible] = useState(false);

  // Page load animation
  useEffect(() => {
    setIsLoaded(true);
    
    // Set title to visible after a short delay
    const timer = setTimeout(() => {
      setIsTitleVisible(true);
    }, 300);
    
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles.worksScreen}>
      <div className={`${styles.titleSection} ${isTitleVisible ? styles.visible : ''}`}>
        <img 
          src={TitleImage} 
          alt="Works title background" 
          className={styles.backgroundImage} 
        />
        <div className={styles.overlay}></div>
        <div className={styles.title}>
          <h1>Works</h1>
        </div>
      </div>
      
      <div className={styles.container}>
        <div className={styles.subtitle}>
          <span className={styles.impact}>미니멀리즘 원칙</span>에 따라 설계된<br />
          다양한 프로젝트들을 소개합니다.
        </div>

        <div className={styles.projectsGrid}>
          {projects.map((project, index) => (
            <div
              key={project.id}
              className={`${styles.projectCard} ${isLoaded ? styles.loaded : ''}`}
              style={{ animationDelay: `${0.1 * index}s` }}
            >
              <Link to={`/works/${project.slug}`} className={styles.projectLink}>
                <div className={styles.projectImage}>
                  <div className={styles.imageContainer}>
                    {project.imageUrl ? (
                      <img src={project.imageUrl} alt={project.title} />
                    ) : (
                      <div className={styles.imagePlaceholder}>
                        <span>{project.id}</span>
                      </div>
                    )}
                  </div>
                  <div className={styles.projectOverlay}>
                    <span className={styles.viewText}>View Project</span>
                  </div>
                </div>
                <div className={styles.projectInfo}>
                  <h2 className={styles.projectTitle}>{project.title}</h2>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WorksScreen;